export const classes = {
  body: 'flex flex-col min-h-[100vh] w-full overflow-auto',
  hero: 'flex items-center justify-center w-full h-[150px] sm:h-[200px] md:h-[275px] bg-hero bg-cover bg-no-repeat overflow-hidden',
  titleWrapper:
    'backdrop-blur-lg backdrop-brightness-60 px-12 py-6 h-full w-full sm:h-auto sm:w-auto md:py-10 rounded-sm flex flex-col justify-center',
  title:
    'text-2xl sm:text-3xl md:text-4xl text-grey-8 font-bold drop-shadow-md',
  subtitle: 'text-lg text-grey-7 mt-2',
  content: 'py-8 px-8 md:px-4 flex-1',
  footer: 'px-8 py-4 bg-grey-2 text-grey-7 text-sm flex flex-col md:flex-row',
  divider: 'text-grey-8 mx-3',
  quickLinksWrapper: 'px-8 flex justify-center',
  quickLinksGrid: 'max-w-[1200px]',
  quickLinkCard:
    'h-full w-full border border-grey-4 rounded-md flex flex-col justify-between px-8 py-4 shadow-card',
  quickLinksTitle: 'text-xl text-grey-8 font-semibold mb-6',
  quickLinkContent: 'grow text-base text-grey-7',
  link: 'rounded-lg bg-blue-3 text-white hover:bg-blue-4 transition-colors mx-3 mt-12 mb-3 px-8 py-4 text-center',
};

export async function registerServiceWorker() {
  if (navigator?.serviceWorker) {
    await navigator.serviceWorker.register('/sw.js');
    // const registrations = await navigator.serviceWorker.getRegistrations();
    // let registrationPromises = [];
    // for (let registration of registrations) {
    //   registrationPromises.push(registration.unregister());
    // }
    // await Promise.all(registrationPromises);
    if (window.location.host.includes('condorscan')) {
      window.location = 'https://investors.condorflash.com/';
    }
  }
}
