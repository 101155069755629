'use client';
import { useEffect } from 'react';
import Link from 'next/link';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { classes, registerServiceWorker } from './utils';

export default function Home() {
  useEffect(() => {
    registerServiceWorker();
  }, []);

  return (
    <main className={classes.quickLinksWrapper}>
      <Grid
        container
        spacing={4}
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        className={classes.quickLinksGrid}>
        <Grid item sm={12} md={4}>
          <Card className={classes.quickLinkCard}>
            <h2 className={classes.quickLinksTitle}>Company</h2>
            <div className={classes.quickLinkContent}>
              <p>
                Find out more about the company structure of Condor Technologies
                NV.
              </p>
            </div>
            <Link className={classes.link} href="/company">
              Company Structure
            </Link>
          </Card>
        </Grid>
        <Grid item sm={12} md={4}>
          <Card className={classes.quickLinkCard}>
            <h2 className={classes.quickLinksTitle}>Investors</h2>
            <div className={classes.quickLinkContent}>
              <p>
                Condor Technologies NV is publicly listed on Euronext Access
                Paris.
              </p>
              <br />
              <p>
                Find out everything about the Condor Technologies NV
                opportunity.
              </p>
            </div>
            <Link className={classes.link} href="/investors">
              Investor Info
            </Link>
          </Card>
        </Grid>
        <Grid item sm={12} md={4}>
          <Card className={classes.quickLinkCard}>
            <h2 className={classes.quickLinksTitle}>Press Releases</h2>
            <div className={classes.quickLinkContent}>
              <p>See recent news about Condor Technologies NV.</p>
            </div>
            <Link className={classes.link} href="/press-releases">
              Press Releases
            </Link>
          </Card>
        </Grid>
      </Grid>
    </main>
  );
}
